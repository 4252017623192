
export  const viewMoreUrls = {
  'Cooking': '/categories/cooking-games',
  'Exclusive': '/categories/exclusive-games',
  'New Games': '/categories/new-games',
  'LOL Surprise':'/categories/lol-surprise-games',
  'Christmas':'/categories/christmas-games',
  'Halloween':'/categories/halloween-games',
  'Doctor':'/categories/doctor-games',
  'Dino Games':'/categories/dino-games',
  'Snake Games':'/categories/snake-games',
  'Mahjong Games':'/categories/mahjong-games',
  'Roblox Games':'/categories/roblox-games',
  'Toca boca Games':'/categories/toca-boca',
  'Card Games':'/categories/card-games',
  'Papas Games':'/categories/papas-games',
};


// 