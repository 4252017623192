import dynamic from 'next/dynamic';
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import SkeletonLoader from './SkeletonLoader';
import { GameList } from "../list/GameList";
import { viewMoreUrls } from "../list/viewMoreUrls";

const HorizontalScroll = dynamic(() => import('./HorizontalScroll'), {
  loading: () => <SkeletonLoader/>,
});
const Ad_320x50 = dynamic(() => import('../ads/Ad_320x50'), {  });
const Ad_728x90_Bot = dynamic(() => import('../ads/Ad_728x90_Bot'), {  });


export default function Game_Components() {

  const path = useRouter();
  const [mobile, setMobile] = useState(false);

  
  useEffect(() => {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    setMobile(isMobile);
  }, []);
  

// *************************************************************

const first50Games = GameList.slice(0, 50);


const getGamesByTag = (tag) => {
  try {
    if (!GameList || !Array.isArray(GameList)) {
      console.error('GameList is undefined or not an array');
      return [];
    }

    const filteredGames = GameList.filter(game => game.tag.includes(tag));

    // while (filteredGames.length < 10) {
    //   filteredGames.push(...filteredGames.slice(0, 10 - filteredGames.length));
    // }

    return filteredGames.slice(0, 30);
    // return filteredGames;
  } catch (error) {
    console.error('Error filtering games:', error);
    return [];
  }
};

  return (
    <>
    {path.pathname =="/"&&<HorizontalScroll games={getGamesByTag('Exclusive')} category="Recommended Games" viewMoreUrl={viewMoreUrls['Exclusive']} layoutType={"double"} showhot={'yes'}/>}
    <HorizontalScroll games={first50Games} category="New Games" viewMoreUrl={viewMoreUrls['New Games']} layoutType={"single"}  />
    <HorizontalScroll games={getGamesByTag('Halloween')} category="Halloween Games" viewMoreUrl={viewMoreUrls['Halloween']} layoutType={"single"}/>
    <HorizontalScroll games={getGamesByTag('Toca boca')} category="Toca boca Games" viewMoreUrl={viewMoreUrls['Toca boca Games']} layoutType={"single"} />
    <HorizontalScroll games={getGamesByTag('Cooking')} category="Cooking Games" viewMoreUrl={viewMoreUrls['Cooking']} layoutType={"single"} />
    {mobile && <Ad_320x50 />} 
    {!mobile && <Ad_728x90_Bot />} 
    <HorizontalScroll games={getGamesByTag('Roblox')} category="Roblox Games" viewMoreUrl={viewMoreUrls['Roblox Games']} layoutType={"single"} />
    <HorizontalScroll games={getGamesByTag('Dino')} category="Dino Games" viewMoreUrl={viewMoreUrls['Dino Games']} layoutType={"single"} />
    <HorizontalScroll games={getGamesByTag('LOL Surprise')} category="LOL Surprise Games" viewMoreUrl={viewMoreUrls['LOL Surprise']} layoutType={"single"} />
    <HorizontalScroll games={getGamesByTag('Christmas')} category="Christmas Games" viewMoreUrl={viewMoreUrls['Christmas']} layoutType={"single"}/>
    {mobile && <Ad_320x50 />} 
    {!mobile && <Ad_728x90_Bot />} 
    <HorizontalScroll games={getGamesByTag('Doctor')} category="Doctor Games" viewMoreUrl={viewMoreUrls['Doctor']} layoutType={"single"}/>
    <HorizontalScroll games={getGamesByTag('Snake')} category="Snake Games" viewMoreUrl={viewMoreUrls['Snake Games']} layoutType={"single"} />
    <HorizontalScroll games={getGamesByTag('Mahjong')} category="Mahjong Games" viewMoreUrl={viewMoreUrls['Mahjong Games']} layoutType={"single"} />
    <HorizontalScroll games={getGamesByTag('Card')} category="Card Games" viewMoreUrl={viewMoreUrls['Card Games']} layoutType={"single"} />
    <HorizontalScroll games={getGamesByTag('Papas')} category="Papa's Games" viewMoreUrl={viewMoreUrls['Papas Games']} layoutType={"single"} />
    {mobile && <Ad_320x50 />} 
    {!mobile && <Ad_728x90_Bot />} 
  
    </>
  );
}
